import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Alert, Button, Layout } from 'antd';

import { faBars } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { MenuSidebar } from '../components';
import { NetworkStatus, SelectedApp, SupportedAppsContext, useNetworkStore } from '../context';
import { getBase } from '../utils';

export const LightLayout = ({ children }) => {
  const status = useNetworkStore('status');
  const location = useLocation();
  const history = useHistory();
  const [apps, setApps] = useState([]);
  const [selectedApp, setSelectedApp] = useState({});
  const [collapsed, setCollapsed] = useState(false);
  const [supportedStores, setSupportedStores] = useState([]);

  const { app_id: app } = useParams();

  const loadData = async () => {
    getBase({
      method: 'GET',
      resource: 'apps/all'
    }).then(response => {
      let currentSelectedAppId = localStorage.getItem('appopify-selected-app') || null;

      const currentSelectedApp = response?.data?.find(app => app.id === currentSelectedAppId);

      // On first load currentSelectedApp will be null
      if (!currentSelectedApp) {
        currentSelectedAppId = response?.data?.find(Boolean)?.id;
        localStorage.setItem('appopify-selected-app', currentSelectedAppId);
      }

      const selectedApp = currentSelectedAppId
        ? response?.data?.find(app => app.id === currentSelectedAppId)
        : response?.data?.find(Boolean);

      setApps(response?.data);
      setSelectedApp(selectedApp);
    });

    getBase({
      method: 'GET',
      resource: 'apps/supported-stores'
    }).then(response => {
      setSupportedStores(response?.data);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <NetworkStatus value={status}>
      <SelectedApp.Provider
        value={{
          apps: apps || [],
          selected: selectedApp || {},
          updateApps: ({ apps }) => {
            setApps(apps);
            if (!selectedApp?.id) {
              //we don't have any app selected select first
              setSelectedApp(apps?.find(Boolean));
            }
          },
          selectApp: ({ app }) => {
            const baseRoute = location.pathname.slice(0, location.pathname.lastIndexOf('/'));
            if (baseRoute.includes('customize')) {
              history.push(`${baseRoute}/${app.id}`);
            }

            setSelectedApp(app);
          }
        }}
      >
        <SupportedAppsContext.Provider value={supportedStores}>
          <Layout style={{ minHeight: '100vh' }}>
            <MenuSidebar collapsed={collapsed} />
            <Layout>
              <Layout.Header
                style={{
                  padding: 0,
                  background: '#FFF'
                }}
              >
                <Button
                  type="text"
                  icon={<FontAwesomeIcon className="menu-icon" icon={faBars} />}
                  onClick={() => setCollapsed(!collapsed)}
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64
                  }}
                />
              </Layout.Header>

              <Layout.Content
                style={{
                  padding: 24,
                  minHeight: 280
                }}
              >
                {status?.code && <Alert closeIcon={true} type="error" message={status?.message} />}
                {children}
              </Layout.Content>
            </Layout>
          </Layout>
        </SupportedAppsContext.Provider>
      </SelectedApp.Provider>
    </NetworkStatus>
  );
};
