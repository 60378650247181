// import React, { useEffect, useState, useContext } from 'react';
// import { getBase } from '../utils';

// const Context = React.createContext({});
// const SelectedAppContex = init => useContext(Context);

// export const SelectedAppProvider = ({ children }) => {
//   const [apps, setApps] = useState([]);
//   console.log('hey');

//   const loadData = async () => {
//     const items = await getBase({
//       method: 'GET',
//       resource: 'apps/all'
//     });

//     setApps(items?.data);
//     console.log('items apps', items?.data);
//   };

//   useEffect(() => {
//     console.log('!!!!make apps requests');

//     loadData();
//   }, []);

//   return (
//     <>
//       <SelectedAppContex.Provider value="some value">{children}</SelectedAppContex.Provider>
//     </>
//   );
// };

import React, { useContext, useEffect, useState } from 'react';

const Context = React.createContext({});
const AffiliateContext = init => useContext(Context);

export const SelectedAppProvider = ({ children }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    const items = await getBase({
      method: 'GET',
      resource: 'apps/all'
    });

    setData(items?.data);
    console.log('items apps', items?.data);
  };
  useEffect(() => {
    getAffiliates();
  }, []);

  return <AffiliateContext.Provider value={{ list: data }}>{children}</AffiliateContext.Provider>;
};
