import React, { Suspense, useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';

import { NoAuthLayout } from '../../layouts';

export const PublicRenderer = ({ isAuthenticated, routeItems, allowedRoutes }) => {
  const location = useLocation();
  const [needsRedirect, setNeedsRedirect] = useState(false);

  useEffect(() => {
    const redirect = !allowedRoutes.find(route => route.path === location.pathname);
    setNeedsRedirect(redirect);
  }, [location, allowedRoutes]);

  if (!isAuthenticated && needsRedirect) {
    return <Redirect to="/login" />;
  }

  return (
    <NoAuthLayout>
      {routeItems.map((routeItem, key) => {
        const { exact, path, Component } = routeItem;
        return (
          <Route
            key={`non-auth-route-${key}`}
            exact={exact}
            path={path}
            render={() => <Suspense fallback={null}>{Component && <Component />}</Suspense>}
          />
        );
      })}
    </NoAuthLayout>
  );
};
