import React from 'react';

import ImageLogo from '../media/logo.png';

export const Logo = ({ width = '120' }) => {
  return (
    <>
      <img
        src={ImageLogo}
        style={{ maxWidth: `${width}px`, width: '90%' }}
        className="mx-2 mt-2 mb-2"
      />
    </>
  );
};
