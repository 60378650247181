import React from 'react';
import { Button, Card, Col, Row } from 'antd';

import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Logo } from '../components';

export const NotFound = () => {
  return (
    <Row className="mt-6 justify-content-md-center">
      <Col xl="3" lg="4" md="6">
        <Card className="mt-4 pt-4" small>
          <Row>
            <Col lg="12" className="text-center">
              <Logo width="240" />
            </Col>
          </Row>

          <p style={{ textAlign: 'center' }}>Woopsy. Page not found</p>

          <Button size="lg" block href="/login">
            <FontAwesomeIcon icon={faSignInAlt} /> Login
          </Button>

          <Row>
            <Col className="text-center">OR</Col>
          </Row>

          <Button size="lg" block href="/register">
            <FontAwesomeIcon icon={faSignInAlt} /> Register
          </Button>
        </Card>
      </Col>
    </Row>
  );
};
