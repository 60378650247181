import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { NotFound } from './pages/NotFound';
import { PrivateRenderer, PublicRenderer } from './components';
import { routes } from './routes';

import './styles/main.scss';
import 'react-quill/dist/quill.snow.css';

export const App = () => {
  const location = window.location;
  const localToken = localStorage?.getItem('appopify-token');

  const isAuthenticated = !!localToken;
  const nonAuthRoutes = routes.filter(({ auth }) => auth === false);
  const authRoutes = routes.filter(({ auth }) => auth === true);

  if (isAuthenticated && location.pathname.includes('login')) {
    console.log(' redirect');
    //we don't have access to useHistory here so we do it the old way :D
    window.location.pathname = '/';
  }

  return (
    <Router>
      <Switch>
        {isAuthenticated && (
          <PrivateRenderer
            isAuthenticated={isAuthenticated}
            routeItems={authRoutes}
            allowedRoutes={authRoutes}
          />
        )}

        <PublicRenderer
          isAuthenticated={isAuthenticated}
          routeItems={nonAuthRoutes}
          allowedRoutes={nonAuthRoutes}
        />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
