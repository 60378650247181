import React, { useContext } from 'react';

export const NetworkContext = React.createContext({});

export const NetworkStatus = NetworkContext.Provider;

//Setters and getters for the context
export const useNetworkStore = key => {
  // console.log('use network status', key, useContext(NetworkContext));
  return NetworkContext?.[key];
};
export const setNetworkStore = ({ code, message }) => {
  NetworkContext.status = {
    code,
    message
  };
};
