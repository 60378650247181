import React, { useContext } from 'react';

export const SelectedApp = React.createContext({
  apps: [],
  selected: {},
  updateApps: () => {},
  selectApp: () => {}
});

//Setters and getters for the context
export const useAllApps = () => {
  const apps = useContext(SelectedApp);
  return apps;
};

export const useSelectedApp = () => {
  const apps = useContext(SelectedApp);
  return apps?.selected || apps?.find(Boolean);
};
