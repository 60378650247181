import React, { Suspense } from 'react';
import { Redirect, Route } from 'react-router-dom';

import { LightLayout } from '../../layouts/LightLayout';

export const PrivateRenderer = ({ isAuthenticated, routeItems }) => {
  if (!isAuthenticated) {
    return <Redirect to="/login" />;
  }

  return (
    <LightLayout>
      {routeItems.map((routeItem, key) => {
        const { exact, path, Component } = routeItem;
        return (
          <Route
            key={`auth-route-${key}`}
            exact={exact}
            path={path}
            render={() => <Suspense fallback={null}>{Component && <Component />}</Suspense>}
          />
        );
      })}
    </LightLayout>
  );
};
