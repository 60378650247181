import React from 'react';
import { Col, Layout, Row } from 'antd';

export const NoAuthLayout = ({ children }) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Row justify="center" style={{ marginTop: '1.5rem' }} gutter={[16, 24]}>
        <Col>{children}</Col>
      </Row>
    </Layout>
  );
};
