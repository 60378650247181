import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom';
import { Layout, Menu } from 'antd';

import { faAppStore } from '@fortawesome/free-brands-svg-icons';
import {
  faBars,
  faBell,
  faBox,
  faCat,
  faCheck,
  faCogs,
  faHome,
  faLanguage,
  faMobileAlt,
  faMoneyCheckAlt,
  faPaintBrush,
  faPhotoVideo,
  faPlusCircle,
  faSignOutAlt,
  faTruck
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useSelectedApp } from '../context';

import { AppsSelect } from './AppsSelect';
import { Logo } from './';

export const MenuSidebar = ({ collapsed }) => {
  const selectedApp = useSelectedApp();
  const appId = selectedApp?.id || null;
  const [ownStoreItems, setOwnStoreItems] = useState([]);

  useEffect(() => {
    //reset each time the values change
    setOwnStoreItems([]);

    if (selectedApp?.storeType === 'own_catalogue') {
      const items = [
        {
          key: '15',
          icon: <FontAwesomeIcon className="menu-icon" icon={faBox} />,
          label: <Link to={`/customize/products/${appId}`}>Products</Link>
        },
        {
          key: '16',
          icon: <FontAwesomeIcon className="menu-icon" icon={faCat} />,
          label: <Link to={`/customize/categories/${appId}`}>Categories</Link>
        },
        {
          key: '17',
          icon: <FontAwesomeIcon className="menu-icon" icon={faTruck} />,
          label: <Link to={`/customize/shipping/${appId}`}>Shipping</Link>
        }
      ];
      setOwnStoreItems(items);
    }
  }, [selectedApp, appId]);

  return (
    <Layout.Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      style={{ backgroundColor: '#FFF' }}
    >
      <Logo />
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={['1']}
        items={[
          {
            key: '1',
            icon: <FontAwesomeIcon className="menu-icon" icon={faHome} />,
            label: <Link to="/">Home</Link>
          },
          {
            key: '2',
            icon: <FontAwesomeIcon className="menu-icon" icon={faMobileAlt} />,
            label: <Link to="/apps">Manage Apps</Link>
          },
          {
            key: '3',
            icon: <FontAwesomeIcon className="menu-icon" icon={faPlusCircle} />,
            label: <Link to="/add-app">Add App</Link>
          },
          {
            key: '4',
            icon: <FontAwesomeIcon className="menu-icon" icon={faCogs} />,
            label: <Link to="/setting">Settings</Link>
          },
          {
            key: '5',
            icon: <FontAwesomeIcon className="menu-icon" icon={faSignOutAlt} />,
            label: <Link to="/logout">Logout</Link>
          }
        ]}
      />

      {selectedApp?.id && (
        <>
          <AppsSelect />
          <Menu
            items={[
              {
                key: '11',
                icon: <FontAwesomeIcon className="menu-icon" icon={faPhotoVideo} />,
                label: <Link to={`/customize/app_icon/${appId}`}>Media</Link>
              },
              {
                key: '12',
                icon: <FontAwesomeIcon className="menu-icon" icon={faPaintBrush} />,
                label: <Link to={`/customize/color_pallet/${appId}`}>Colors</Link>
              },
              {
                key: '13',
                icon: <FontAwesomeIcon className="menu-icon" icon={faBars} />,
                label: <Link to={`/customize/menu/${appId}`}>Menu</Link>
              },
              {
                key: '14',
                icon: <FontAwesomeIcon className="menu-icon" icon={faBell} />,
                label: <Link to={`/customize/notifications/${appId}`}>Notifications</Link>
              },
              ...ownStoreItems,
              {
                key: '18',
                icon: <FontAwesomeIcon className="menu-icon" icon={faMoneyCheckAlt} />,
                label: <Link to={`/customize/payment/${appId}`}>Payments</Link>
              },
              {
                key: '19',
                icon: <FontAwesomeIcon className="menu-icon" icon={faLanguage} />,
                label: <Link to={`/customize/translations/${appId}`}>Translations</Link>
              },
              {
                key: '20',
                icon: <FontAwesomeIcon className="menu-icon" icon={faAppStore} />,
                label: <Link to={`/customize/appstores/${appId}`}>App Stores</Link>
              },
              {
                key: '21',
                icon: <FontAwesomeIcon className="menu-icon" icon={faCheck} />,
                label: <Link to={`/customize/testing/${appId}`}>App Testing</Link>
              }
            ]}
          />
        </>
      )}
    </Layout.Sider>
  );
};
