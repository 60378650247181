import axios from 'axios';

import { config } from '../config';
import { setNetworkStore } from '../context';

export const getBase = ({ method = 'GET', resource, headers, data, options }) => {
  return axios({
    method,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('appopify-token')}`,
      ...headers
    },
    url: `${config.API_URL}/${resource}`,
    data,
    ...options
  })
    .then(response => {
      setNetworkStore({});
      return response?.data;
    })
    .catch(error => {
      if (!error?.resource && error.toString().includes('Network Error')) {
        setNetworkStore({
          code: 'network',
          message: 'API response error - The server failed to fulfill the request, please try again'
        });
        return;
      } else if (!error?.resource && error.toString().includes('429')) {
        setNetworkStore({
          code: 'network',
          message: 'API response error - To many requests.'
        });
        return;
      } else if (error?.response?.status !== 401) {
        setNetworkStore({
          code: 'network',
          message: error?.toString()
        });
      }

      const { status } = error?.response;
      if (status === 401) {
        //Unauthorized
        localStorage.clear();
        // @todo - do via react router
        window.location.href = '/';
      }
      throw error?.response || error;
    });
};

/**
 * Helper Function to get categories
 * @param {uuid} appId
 */
export const getAllCategories = async ({ appId, selectOne = true }) => {
  return getBase({ resource: `categories/${appId}` })
    .then(response => {
      const parentCategory = [{ value: null, label: 'Select One' }];
      const allCategories = response?.data?.map(category => ({
        value: category.id,
        label: category.name,
        ...category
      }));
      const categoriesList = selectOne ? parentCategory.concat(allCategories) : allCategories;
      //@todo - map children
      return categoriesList;
    })
    .catch(err => {
      console.log('gete cat err', err);
    });
};

/**
 * Helper Function to get products
 * @param {uuid} appId
 * @param {string} searchTerm
 */
export const getAllProducts = async ({ appId, searchTerm }) => {
  return getBase({ resource: `products/all/${appId}?q=${searchTerm || ''}` })
    .then(response => {
      console.log('get all products', response);
      return response;
    })
    .catch(err => {
      console.log('gete cat err', err);
    });
};

/**
 * Helper Function to delete an item
 * @param {uuid} id
 * @param {string} resource
 */
export const deleteResource = async ({ id, resource }) => {
  return getBase({ resource, method: 'DELETE' })
    .then(response => response)
    .catch(err => {
      console.log('Delete err', err);
      return err;
    });
};
