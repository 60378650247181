import { lazy } from 'react';

export const routes = [
  /**
   * Non Auth Routes
   */
  {
    path: '/auth',
    Component: lazy(() =>
      import('./pages/auth').then(({ AuthComp }) => ({
        default: AuthComp
      }))
    ),
    exact: true,
    auth: false
  },
  {
    path: '/login',
    Component: lazy(() =>
      import('./pages/auth').then(({ Login }) => ({
        default: Login
      }))
    ),
    exact: true,
    auth: false
  },
  {
    path: '/logout',
    Component: lazy(() =>
      import('./pages/auth').then(({ Logout }) => ({
        default: Logout
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/register',
    Component: lazy(() =>
      import('./pages/auth').then(({ Register }) => ({
        default: Register
      }))
    ),
    exact: true,
    auth: false
  },
  {
    path: '/auth-error/:entity',
    Component: lazy(() =>
      import('./pages/auth').then(({ AuthError }) => ({
        default: AuthError
      }))
    ),
    exact: true,
    auth: false
  },
  {
    path: '/forgot-password',
    Component: lazy(() =>
      import('./pages/auth').then(({ ForgotPassword }) => ({
        default: ForgotPassword
      }))
    ),
    exact: true,
    auth: false
  },
  /**
   * Auth Routes
   */
  {
    path: '/apps',
    Component: lazy(() =>
      import('./pages/Apps').then(({ Apps }) => ({
        default: Apps
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/app/edit/:app_id',
    Component: lazy(() =>
      import('./pages/EditApp').then(({ EditApp }) => ({
        default: EditApp
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/',
    Component: lazy(() =>
      import('./pages/Dashboard').then(({ Dashboard }) => ({
        default: Dashboard
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/settings',
    Component: lazy(() =>
      import('./pages/Settings').then(({ Settings }) => ({
        default: Settings
      }))
    ),
    exact: true,
    auth: true
  },
  // Splash Screen and App Icon
  {
    path: '/customize/splash_screen/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ SplashScreen }) => ({
        default: SplashScreen
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/customize/app_icon/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ AppIcon }) => ({
        default: AppIcon
      }))
    ),
    exact: true,
    auth: true
  },
  // Color Pallet
  {
    path: '/customize/color_pallet/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ ColorPallet }) => ({
        default: ColorPallet
      }))
    ),
    exact: true,
    auth: true
  },
  // Menu
  {
    path: '/customize/menu/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ Menu }) => ({
        default: Menu
      }))
    ),
    exact: true,
    auth: true
  },
  // Notifications
  {
    path: '/customize/notifications/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ Notifications }) => ({
        default: Notifications
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: ['/customize/notifications/add/:app_id', '/customize/notifications/edit/:id'],
    Component: lazy(() =>
      import('./pages/customization').then(({ NotificationAdd }) => ({
        default: NotificationAdd
      }))
    ),
    exact: true,
    auth: true
  },
  // Products
  {
    path: [
      '/customize/products/:app_id',
      '/customize/products/add/:app_id',
      '/customize/products/edit/:productId',
      '/customize/products_attributes/:app_id',
      '/customize/products_attributes/add/:app_id',
      '/customize/products_attributes/edit/:id'
    ],
    Component: lazy(() =>
      import('./pages/customization').then(({ ProductsList }) => ({
        default: ProductsList
      }))
    ),
    exact: true,
    auth: true
  },
  // {
  //   path: ['/customize/products/add/:app_id', '/customize/products/edit/:productId'],
  //   Component: lazy(() =>
  //     import('./pages/customization').then(({ ProductsAdd }) => ({
  //       default: ProductsAdd
  //     }))
  //   ),
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: '/customize/products_attributes/:app_id',
  //   Component: lazy(() =>
  //     import('./pages/customization').then(({ ProductsAttributes }) => ({
  //       default: ProductsAttributes
  //     }))
  //   ),
  //   exact: true,
  //   auth: true
  // },
  // {
  //   path: ['/customize/products_attributes/add/:app_id', '/customize/products_attributes/edit/:id'],
  //   Component: lazy(() =>
  //     import('./pages/customization').then(({ ProductsAttributesAdd }) => ({
  //       default: ProductsAttributesAdd
  //     }))
  //   ),
  //   exact: true,
  //   auth: true
  // },
  {
    path: '/customize/products_categories/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ ProductsCategories }) => ({
        default: ProductsCategories
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/customize/categories/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ Categories }) => ({
        default: Categories
      }))
    ),
    exact: true,
    auth: true
  },
  // Payment Gateways
  {
    path: '/customize/payment/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ Payments }) => ({
        default: Payments
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/customize/payment/add/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ AddPayments }) => ({
        default: AddPayments
      }))
    ),
    exact: true,
    auth: true
  },
  // Shipping
  {
    path: '/customize/shipping/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ Shipping }) => ({
        default: Shipping
      }))
    ),
    exact: true,
    auth: true
  },
  // App Stores
  {
    path: '/customize/appstores/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ AppStores }) => ({
        default: AppStores
      }))
    ),
    exact: true,
    auth: true
  },
  // App Testing
  {
    path: '/customize/testing/:app_id',
    Component: lazy(() =>
      import('./pages/customization').then(({ AppTesting }) => ({
        default: AppTesting
      }))
    ),
    exact: true,
    auth: true
  },
  // App Translations
  {
    path: '/customize/translations/:app_id',
    Component: lazy(() =>
      import('./pages/customization/Translations').then(({ Translations }) => ({
        default: Translations
      }))
    ),
    exact: true,
    auth: true
  },
  // Add App Wizzard
  {
    path: '/add-app',
    Component: lazy(() =>
      import('./pages/wizard').then(({ AddApp }) => ({
        default: AddApp
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/add-app-step/:app_id',
    Component: lazy(() =>
      import('./pages/wizard').then(({ AddAppStep }) => ({
        default: AddAppStep
      }))
    ),
    exact: true,
    auth: true
  },
  {
    path: '/add-app-final/:app_id',
    Component: lazy(() =>
      import('./pages/wizard').then(({ AddAppFinal }) => ({
        default: AddAppFinal
      }))
    ),
    exact: true,
    auth: true
  }
];
