import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Dropdown, Row } from 'antd';

import { faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAllApps } from '../context';

export const AppsSelect = () => {
  const { apps, selected, selectApp } = useAllApps();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if (apps) {
      const formattedMenuItems = apps.map(app => ({
        label: app.name,
        value: app.id,
        key: app.id
      }));
      setMenuItems(formattedMenuItems);
    }
  }, [apps]);

  const onSelect = selected => {
    const selectedApp = apps?.find(app => app.id === selected.key);
    localStorage.setItem('appopify-selected-app', selectedApp?.id || null);
    selectApp({ app: selectedApp });
  };

  return (
    <>
      {apps?.length === 0 ? (
        <Link to="/add-app">
          <Button type="primary">Add App Now</Button>
        </Link>
      ) : (
        <Row gutter={[16, 16]} justify="center">
          <Col>
            <Dropdown.Button
              menu={{ items: menuItems, onClick: onSelect }}
              icon={<FontAwesomeIcon icon={faArrowDown} />}
              size="medium"
              className="appSelectMenuDropDown"
              overlayStyle={{ width: 'max-content' }}
            >
              {selected.name}
            </Dropdown.Button>
          </Col>
        </Row>
      )}
    </>
  );
};
